import React, { useEffect, useState } from "react";
import useMediaQuery from "./hooks/useMediaQuery";

const Build: React.FC = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [buildNumber, setbuildNumber] = useState("-");

  useEffect(() => {
    let url = process.env.PUBLIC_URL + "/build.json";
    fetch(url)
      .then(res => res.json())
        .then(data => {
            setbuildNumber(data.build_number);
        });
  }, []);
  return (
    <div
      style={{
        position: "absolute",
        bottom: "0",
        left: "50%",
        transform: "translateX(-50%)",
        fontSize: "0.6rem",
        color: "#aaa",
        display: isMobile ? "none" : "block"
      }}
    >
      Build Number: {buildNumber} | &copy; BHEUNI.io
    </div>
  );
};

export default Build;
