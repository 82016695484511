import { Layout, Carousel, Drawer, Skeleton, Typography } from "antd";
import Chat from "./Chat";
import { QueryClient, QueryClientProvider } from "react-query";
import useMediaQuery from "./hooks/useMediaQuery";
import { MenuOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import CourseSearch from "./Course-Search";

const { Content, Sider } = Layout;
// Create a client
const queryClient = new QueryClient();

const App = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [isDrawer, setisDrawer] = useState(false);
  const [isContentLoading, setisContentLoading] = useState(true);
  const [currentPage, setcurrentPage] = useState("");

  useEffect(() => {
    // if the url contains /course-search/{somehash} then the page is course search, else it is chat
    const path = window.location.pathname;
    if (path.includes("/course-search")) {
      setcurrentPage("course-search");
    } else {
      setcurrentPage("chat");
    }
    setisContentLoading(false);
  }, []);

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <Layout style={{ minHeight: "100vh" }}>
          {isMobile && (
            <header
              style={{
                height: 50,
                background: "#21346D",
                color: "#fff",
                lineHeight: "50px",
                padding: "0 20px",
                position: "fixed",
                width: "100vw"
              }}
            >
              <MenuOutlined onClick={() => setisDrawer(!isDrawer)} />
              &nbsp;{" "}
              <img
                src={process.env.PUBLIC_URL + "/mobile-logo.png"}
                style={{
                  height: 30,
                  marginTop: -10,
                  marginLeft: 10
                }}
              />
            </header>
          )}
          <Drawer
            open={isDrawer}
            // from left
            placement="left"
            onClose={() => setisDrawer(false)}
            // something related to marketing title
            title="BHE Uni"
          >
            <Carousel autoplay>
              <div
                style={{
                  width: "90vw"
                }}
              >
                <img src={process.env.PUBLIC_URL + "/banner1.jpg"} alt="banner" />
              </div>
              <div>
                <img src={process.env.PUBLIC_URL + "/banner2.jpg"} alt="banner" />
              </div>
              <div>
                <img src={process.env.PUBLIC_URL + "/banner3.jpg"} alt="banner" />
              </div>
            </Carousel>
          </Drawer>
          {/* Sidebar */}
          {isMobile ? null : (
            <Sider
              width={300}
              style={{
                backgroundColor: "#F3F4F7",
                borderRight: "1px soild #dedede"
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  height: isMobile ? "70vh" : "100vh"
                }}
              >
                <div>
                  <div>
                    <img
                      src={process.env.PUBLIC_URL + "/uk-flag-banner.png"}
                      style={{ width: 300 }}
                      alt="banner"
                    />
                  </div>
                  <div className="logo">
                    <img src={process.env.PUBLIC_URL + "/logo.png"} alt="logo" />
                  </div>
                  {/* <h1>{isMobile ? "Mobile" : "Desktop"}</h1> */}
                  <div>
                    <Carousel autoplay>
                      <div>
                        <img src={process.env.PUBLIC_URL + "/banner1.jpg"} alt="banner" />
                      </div>
                      <div>
                        <img src={process.env.PUBLIC_URL + "/banner2.jpg"} alt="banner" />
                      </div>
                      <div>
                        <img src={process.env.PUBLIC_URL + "/banner3.jpg"} alt="banner" />
                      </div>
                    </Carousel>
                  </div>
                </div>
                <div>
                  <img src={process.env.PUBLIC_URL + "/bg.svg"} style={{ width: 300 }} />
                </div>
              </div>
            </Sider>
          )}
          <Content style={{ padding: "0 50px", background: "#fff" }}>
            {isContentLoading && (
              <div
                style={{
                  padding: "20px"
                }}
              >
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
              </div>
            )}
            {!isContentLoading && currentPage === "chat" && (
              <div
                style={{
                  width: isMobile ? "90%" : "80%",
                  margin: "0 auto",
                  overflowX: isMobile ? "hidden" : "auto"
                }}
              >
                {isMobile && <p>&nbsp;</p>}
                {isMobile && <p>&nbsp;</p>}
                <Chat isDrawerOpen={isDrawer} />
              </div>
            )}
            {!isContentLoading && currentPage === "course-search" && (
              <>
                <div
                  style={{
                    width: isMobile ? "90%" : "95%",
                    margin: "0 auto",
                    overflowX: isMobile ? "hidden" : "auto"
                  }}
                >
                  <p> &nbsp;</p>
                  <Typography.Title level={3}>Course Search</Typography.Title>
                  <CourseSearch />
                </div>
              </>
            )}
          </Content>
        </Layout>
      </QueryClientProvider>
    </div>
  );
};

export default App;