import { Button, Card, Descriptions, Drawer, Modal, Result, Table } from "antd";
import React, { useEffect, useState } from "react";
import useMediaQuery from "./hooks/useMediaQuery";
import { LinkOutlined } from "@ant-design/icons";
import axios from "axios";
import useSound from "use-sound";
import Lottie from "react-lottie-player";
import lottieJson from "./assets/animation_loading.json";

const CourseSearch: React.FC = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [courseData, setcourseData]: [any, any] = useState({});
  const [courseDrawer, setcourseDrawer] = useState(false);
  const [results, setresults] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [isLoadFailed, setisLoadFailed] = useState(false);
  const [refreshtoken, setrefreshtoken] = useState(0);
  const [showModal, setshowModal] = useState(false);
  const [courseContentShow, setcourseContentShow] = useState(false);

  const wavFile =
    process.env.NODE_ENV === "production"
      ? "/client/notification-sound.wav"
      : "/notification-sound.wav";
  const [play] = useSound(wavFile);

  useEffect(() => {
    setisLoading(true);
    // url: http://localhost:3000/course-search?hash=d62c82b314cf1bf385c10f261372d3ecedf34c13d050cf0f6944ffa51b9b0258
    let session_id = window.location.search.split("=")[1];
    // remove the hash from the url
    session_id = session_id.replace("hash","");
    if(!session_id) { 
      // if the session id is not present, then redirect to the home page
      window.location.href = "/";
      alert("Please start a new session");
      return;
    }
    axios
      .post("/api/getresult", { session_id, skip_extra:true  })
      .then((res) => {
        if (res.data.options && res.data.options.results) {
          setresults(res.data.options.results);
        }
      })
      .catch((err) => {
        setisLoadFailed(true);
      })
      .finally(() => {
        setisLoading(false);
      });
  }, [refreshtoken]);

  return (
    <div>
      {isLoadFailed && !isLoading && (
        <Result
          status="404"
          style={{
            marginTop: 150,
            // nice light grey background for better contrast
            background: "#f7f9ff",
            borderRadius: 10
          }}
          title="There was an error loading the data. Please try again later."
          extra={
            <Button
              type="primary"
              onClick={() => {
                setisLoading(true);
                setisLoadFailed(false);
                setrefreshtoken(refreshtoken + 1);
              }}
            >
              Try Again
            </Button>
          }
        />
      )}
      {!isLoadFailed && (
        <>
          <Table
            loading={isLoading}
            columns={
              isMobile
                ? [
                    {
                      title: " ",
                      render: (text: any, record: any) => (
                        <Card
                          hoverable
                          style={{
                            width: "80vw"
                          }}
                        >
                          <Descriptions
                            title={
                              <>
                                {" "}
                                <img
                                  style={{
                                    width: "25px",
                                    borderRadius: "6px",
                                    marginRight: "12px"
                                  }}
                                  src={process.env.REACT_APP_BACKEND + "/" + record.institute_logo}
                                />{" "}
                                {record.institute_name}
                              </>
                            }
                          >
                            <Descriptions.Item label="Campus">
                              {record.campus_name}
                            </Descriptions.Item>
                            <Descriptions.Item label="Country">
                              {record.country_name}
                            </Descriptions.Item>
                            <Descriptions.Item label="Course Count">
                              {record.course_count}
                            </Descriptions.Item>
                            <Descriptions.Item label="QS World Ranking">
                              {record.qs_world}
                            </Descriptions.Item>
                            <Descriptions.Item label="Web Ranking (World)">
                              {record.webometrics_world}
                            </Descriptions.Item>
                            <Descriptions.Item label="Web Ranking (Country)">
                              {record.webometrics_national}
                            </Descriptions.Item>

                            <p>
                              <Button
                                type="primary"
                                onClick={() => {
                                  setcourseData(record);
                                  setcourseDrawer(true);
                                }}
                              >
                                View Courses
                              </Button>
                            </p>
                          </Descriptions>
                        </Card>
                      )
                    }
                  ]
                : ([
                    {
                      title: "University",
                      dataIndex: "institute_name",
                      key: "institute_name",
                      sorter: (a: any, b: any) => a.institute_name.length - b.institute_name.length,
                      render: (text: any, record: any) => (
                        <Button
                          type="link"
                          onClick={() => {
                            setcourseData(record);
                            setcourseDrawer(true);
                          }}
                        >
                          <img
                            src={process.env.REACT_APP_BACKEND + "/" + record.institute_logo}
                            width={25}
                          />{" "}
                          &nbsp; {text} <LinkOutlined />
                        </Button>
                      )
                    },
                    {
                      title: "Country",
                      dataIndex: "country_name",
                      key: "country_name",
                      sorter: (a: any, b: any) => a.country_name.length - b.country_name.length
                    },
                    {
                      title: "Campus",
                      dataIndex: "campus_name",
                      key: "campus_name",
                      sorter: (a: any, b: any) => a.campus_name.length - b.campus_name.length
                    },
                    ,
                    {
                      title: "Course Count",
                      dataIndex: "course_count",
                      key: "course_count",
                      sorter: (a: any, b: any) => a.course_count - b.course_count
                    },
                    {
                      title: "QS World Ranking",
                      dataIndex: "qs_world",
                      key: "qs_world",
                      sorter: (a: any, b: any) => {
                        if (!a.qs_world) return 1;
                        if (!b.qs_world) return -1;
                        return a.qs_world - b.qs_world;
                      }
                    },
                    {
                      title: "Web Ranking (World)",
                      dataIndex: "webometrics_world",
                      key: "webometrics_world",
                      sorter: (a: any, b: any) => a.webometrics_world - b.webometrics_world
                    },
                    {
                      title: "Web Ranking (Country)",
                      dataIndex: "webometrics_national",
                      key: "webometrics_national",
                      sorter: (a: any, b: any) => a.webometrics_national - b.webometrics_national
                    }
                  ] as any)
            }
            dataSource={results}
          />
        </>
      )}

      <Drawer
        open={courseDrawer}
        onClose={() => setcourseDrawer(false)}
        title={
          <>
            <img
              src={process.env.REACT_APP_BACKEND + "/" + courseData["institute_logo"]}
              width={30}
              style={{
                borderRadius: "6px"
              }}
            />
            {courseData["institute_name"] + " - " + courseData["campus_name"]}
          </>
        }
        width={isMobile ? "98vw" : "50vw"}
      >
        {courseData["courses"] &&
          courseData["courses"].map((course: any) => (
            <div
              style={{
                position: "relative",
                marginBottom: "12px"
              }}
            >
              <img
                //     position: absolute;
                // left: 0px;
                // top: 0px;
                // z-index: 100;
                // width: 50px;
                // top: -20px;
                // left: -20px;
                // border-radius: 8px;
                // opacity: 0.5;
                src={process.env.PUBLIC_URL + "/dot-dot-dot.png"}
                style={{
                  position: "absolute",
                  zIndex: 100,
                  width: "25px",
                  top: "-10px",
                  left: "-1<0px",
                  opacity: 0.5
                }}
              />
              <Card
                hoverable
                style={{
                  marginBottom: "12px",
                  overflow: "hidden"
                }}
              >
                <svg
                  width={600}
                  height={600}
                  viewBox="0 0 600 600"
                  style={{
                    opacity: 0.1,
                    position: "absolute",
                    left: "155px",
                    transform: "rotate(53deg)"
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  className="TwoColumnWithVideo__DecoratorBlob1-sc-apwiss-10 gamNjv"
                >
                  <path
                    d="M176.2-106.9c32.5 61.4 25.6 140.4-10.4 206.3C129.9 165.3 65 218.2-9.2 223.5c-74.2 5.3-157.7-36.8-192.4-102.1-34.8-65.2-21-153.6 20.7-220.3 41.8-66.8 111.3-111.9 182.1-112.6 70.7-.7 142.6 43.2 175 104.6z"
                    fill="#6415ff"
                    transform="translate(300 300)"
                  />
                </svg>
                <Descriptions title={course["course_name"]}>
                  <Descriptions.Item label="Intake">{course["course_intake"]}</Descriptions.Item>
                  <Descriptions.Item label="IELTS Score">{course["ielts_score"]}</Descriptions.Item>
                  <Descriptions.Item label="Fee" span={3}>
                    {course["course_fee"]}
                  </Descriptions.Item>
                  <Descriptions.Item label="Duration" span={2}>
                    {course["course_duration"]}
                  </Descriptions.Item>
                </Descriptions>
                <div
                  style={{
                    textAlign: "right"
                  }}
                >
                  <Button
                    type="primary"
                    onClick={() => {
                      // show a notification as modal with the message "One of our counsellor will contact you soon"
                      setshowModal(true);
                      // play the sound
                      play();
                      setcourseContentShow(false);
                      // after 3 seconds, close the modal
                      setTimeout(() => {
                        setcourseContentShow(true);
                      }, 1000 * 3);
                    }}
                  >
                    Apply Now
                  </Button>
                </div>
              </Card>
            </div>
          ))}
      </Drawer>

      <Modal title="Thank you for your interest" open={showModal} footer={null} closable={false}>
        {courseContentShow && (
          <div>
            <p>
              One of our counsellor will contact you soon. If you have any questions, please feel
              free to contact us at <a href="mailto:info@bheuni.io">info@bheuni.io</a>
            </p>
            <p
              style={{
                textAlign: "right"
              }}
            >
              <Button
                type="primary"
                onClick={() => {
                  setshowModal(false);
                }}
              >
                Close
              </Button>
            </p>
          </div>
        )}
        {!courseContentShow && (
          <Lottie
            loop
            animationData={lottieJson}
            play
            style={{ width: 300, height: 300, margin: "0 auto" }}
          />
        )}
      </Modal>
    </div>
  );
};

export default CourseSearch;
