import React, { useState, useEffect, useRef } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

//@ts-ignore
const Typewriter = ({ text, afterAnimation, beforeAnimation, onEveryType }) => {
  const [displayText, setDisplayText] = useState("");
  const [isComplete, setIsComplete] = useState(false);

  useEffect(() => {
    const intervalTime = Math.max(1, Math.floor(5000 / text.length)); // calculate interval time based on text length
    if (beforeAnimation) {
      beforeAnimation();
    }
    const intervalId = setInterval(() => {
      // add one letter to the display text
      setDisplayText((prevDisplayText) => {
        const nextLetter = text[prevDisplayText.length];

        if (nextLetter) {
          return prevDisplayText + nextLetter;
        }
        clearInterval(intervalId);
        setIsComplete(true);
        return prevDisplayText;
      });
    }, 5);

    return () => {
      clearInterval(intervalId);
    };
  }, [text]);

  useEffect(() => {
    if (isComplete && afterAnimation) {
      afterAnimation();
    }
  }, [isComplete, afterAnimation]);

  useEffect(() => {
    const onEvery250ms = setInterval(() => {
      if (onEveryType) {
        onEveryType();
      }
      if (isComplete) {
        clearInterval(onEvery250ms);
      }
    }, 250);
    return () => {
      clearInterval(onEvery250ms);
    };
  }, [isComplete]);

  return <ReactMarkdown remarkPlugins={[remarkGfm]}>{displayText}</ReactMarkdown>;
};

export default Typewriter;
