import {
  Input,
  Button,
  Spin,
  Typography,
  Popover,
  Alert,
  Divider,
  Drawer,
  Table,
  Card,
  Descriptions,
  message as antdMessage,
  Modal
} from "antd";
import { SendOutlined, EyeOutlined, LinkOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { v4 } from "uuid";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import Typewriter from "./Type";
import mba from "./assets/mba.png";
import postgrad from "./assets/postgrad.png";
import undergrad from "./assets/undergrad.png";
import duolingo from "./assets/duolingo.png";
import ielts from "./assets/ielts.png";
import pte from "./assets/pte.png";
import toefl from "./assets/toefl.png";
import gmat from "./assets/gmat.png";
import gre from "./assets/gre.png";
import mat from "./assets/mat.png";
import sat from "./assets/sat.png";
import act from "./assets/act.png";
import notplainning from "./assets/not-planning.png";
import parsePhoneNumber from "libphonenumber-js";
import useSound from "use-sound";
import Lottie from "react-lottie-player";
import lottieJson from "./assets/animation_loading.json";
import useMediaQuery from "./hooks/useMediaQuery";
import Build from "./build";

const sampleMessages = [
  {
    is_ai: true,
    message:
      "Welcome to BHE Uni's AI Eligibility Checker! Quickly assess your admission eligibility with our smart tool.",
    id: v4(),
    animate: false
  }
];
// process flow:
// 1. Get User Name
// 2. Get User Email
// 3. Get User Phone Number
// 4. Get User Country
// 5. Ask the user, what

function Chat(props: any) {
  const [messages, setMessages]: [any, Function] = useState(sampleMessages);
  const [userMessage, setUserMessage] = useState("");
  const [showLoader, setshowLoader] = useState(false);
  const [currentStage, setcurrentStage] = useState("usercountry");
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [country, setcountry] = useState("");
  const [phonenumber, setphonenumber] = useState("");
  const [countryCode, setcountryCode] = useState("");
  const [userCountryFlag, setuserCountryFlag] = useState("");
  const [degree, setdegree] = useState("");
  const [highesteducation, sethighesteducation] = useState("");
  const [gainedpercentage, setgainedpercentage] = useState("");
  const [educationtest, seteducationtest] = useState("");
  const [educationtestscore, seteducationtestscore] = useState("");
  const [academic, setacademictest] = useState("");
  const [academicscore, setacademicscore] = useState("");
  const [fieldOfStudy, setfieldOfStudy] = useState("");
  const [ServerOptions, setServerOptions] = useState([]);
  const [isPopoverOver, setisPopoverOver] = useState(false);
  const [workexprience, setworkexprience] = useState(0);
  const [nationality, setnationality] = useState("");
  const [session_id, setsession_id] = useState("");
  const [results, setresults] = useState([]);
  const [residential_status, setresidential_status] = useState("");
  const [residential_popover, setresidential_popover] = useState(false);
  const [isDebug, setisDebug] = useState(true);
  const [country_name, setcountry_name] = useState("");
  const [countryCodeUser, setcountryCodeUser] = useState("");
  const [is_eu_or_uk, setis_eu_or_uk] = useState(false);

  const [afterMessage, setafterMessage] = useState("");
  const [inputDisabled, setinputDisabled] = useState(false);
  const [userCountry, setuserCountry] = useState("");
  const [nextStage, setnextStage] = useState("");
  const [hideButton, sethideButton] = useState(true);
  const [inputStatus, setinputStatus]: [any, any] = useState("");
  const [inputHelp, setinputHelp]: [any, any] = useState("");
  const [showErrorShake, setshowErrorShake] = useState(false);
  const [showDrawer, setshowDrawer] = useState(false);
  const [courseDrawer, setcourseDrawer] = useState(false);
  const [courseData, setcourseData]: [any, any] = useState({});
  const [showModal, setshowModal] = useState(false);
  const [courseContentShow, setcourseContentShow] = useState(false);
  const wavFile =
    process.env.NODE_ENV === "production"
      ? "/client/notification-sound.wav"
      : "/notification-sound.wav";
  const [play] = useSound(wavFile);
  const [countryId, setcountryId] = useState("");
  const [categoryId, setcategoryId] = useState("");

  const isMobile = useMediaQuery("(max-width: 768px)");
  const wrapperRef = useRef(null);

  // create ref for scroll to bottom
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);
  const ScrollToBottom = () => {
    //@ts-ignore
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    // scrollerDiv to bottom programatically
    //@ts-ignore
    // scrollerDiv.current.scrollTop = scrollerDiv.current.scrollHeight;
  };
  const customMessage = (message: string, extra?: string) => {
    setUserMessage(message);
    if (currentStage === "field-of-study" && extra) {
      setcategoryId(extra);
    }
    if (currentStage === "country" && extra) {
      setcountryId(extra);
    }
    if (currentStage === "residentialstatus") {
      setresidential_popover(false);
    }
    setTimeout(() => {
      sendMessage(message);
    }, 100);
  };

  const addAIMessage = (
    message: string,
    old_messages: any,
    id: string,
    is_markdown: boolean = false
  ) => {
    // remove all animation by add animate: false
    old_messages = old_messages.map((message: any) => {
      message.animate = false;
      return message;
    });
    old_messages.push({
      is_ai: true,
      message: message,
      animate: true,
      is_markdown: is_markdown,
      id: v4()
    });
    return old_messages;
  };

  const getFlag = (flag: string) => {
    if (flag.includes("data:image")) {
      return flag;
    } else {
      return process.env.REACT_APP_BACKEND + "/" + flag;
    }
  };

  const validateUserInput = (userMessage: string) => {
    if (userMessage === "/debug" || userMessage === "/resend") {
      return true;
    }
    // validation for the phone number
    if (currentStage === "phonenumber") {
      const phoneNumber = parsePhoneNumber(userMessage);
      console.log({
        phoneNumber,
        isValid: phoneNumber?.isValid()
      });

      if (phoneNumber && phoneNumber?.isValid()) {
      } else {
        setinputStatus("error");
        setinputHelp("Please enter a valid phone number");
        return false;
      }
    }
    // validation for the email
    if (currentStage === "email") {
      if (!validateEmail(userMessage)) {
        setinputStatus("error");
        setinputHelp("Please enter a valid email");
        return false;
      }
    }
    // validation for the gained-percentage, it should be a number or float
    // if % is in the message, then remove it
    if (currentStage === "gained-percentage") {
      let _userMessage: any = userMessage.replace("%", "");
      if (isNaN(_userMessage)) {
        setinputStatus("error");
        setinputHelp("Please enter a valid percentage");
        return false;
      }
      // if the percentage is 1 to 100
      if (Number(_userMessage) < 1 || Number(_userMessage) > 100) {
        setinputStatus("error");
        setinputHelp("Please enter a valid percentage");
        return false;
      }
    }
    // education-test validation
    if (currentStage === "education-test") {
      // if the message is not from the options, then return false
      let _options = ["IELTS", "TOEFL", "PTE", "Duolingo", "Not Planing to take Any"];
      if (!_options.includes(userMessage)) {
        setinputStatus("error");
        setinputHelp("Please select a valid option");
        return false;
      }
    }
    // education-score validation
    if (currentStage === "education-score") {
      // education-score should be a number
      // @ts-ignore
      if (isNaN(userMessage)) {
        setinputStatus("error");
        setinputHelp("Please enter a valid score");
        return false;
      } else {
        // for IELTS, the score should be in the range of 0-9
        if (educationtest === "IELTS") {
          if (Number(userMessage) < 0 || Number(userMessage) > 9) {
            setinputStatus("error");
            setinputHelp("Please enter a valid score, in the range of 0-9");
            return false;
          }
        }
        // for TOEFL, the score should be in the range of 0-120
        if (educationtest === "TOEFL") {
          if (Number(userMessage) < 0 || Number(userMessage) > 120) {
            setinputStatus("error");
            setinputHelp("Please enter a valid score, in the range of 0-120");
            return false;
          }
        }
        // for PTE, the score should be in the range of 10-90
        if (educationtest === "PTE") {
          if (Number(userMessage) < 10 || Number(userMessage) > 90) {
            setinputStatus("error");
            setinputHelp("Please enter a valid score, in the range of 10-90");
            return false;
          }
        }
        // for Duolingo, the score should be in the range of 10-160
        if (educationtest === "Duolingo") {
          if (Number(userMessage) < 10 || Number(userMessage) > 160) {
            setinputStatus("error");
            setinputHelp("Please enter a valid score, in the range of 10-160");
            return false;
          }
        }
      }
    }
    // academic-score validation
    if (currentStage === "academic-score") {
      // academic-score should be a number
      // @ts-ignore
      if (isNaN(userMessage)) {
        setinputStatus("error");
        setinputHelp("Please enter a valid score");
        return false;
      }
      // for GRE, the score should be in the range of 260-340
      if (academic === "GRE") {
        if (Number(userMessage) < 260 || Number(userMessage) > 340) {
          setinputStatus("error");
          setinputHelp("Please enter a valid score, in the range of 260-340");
          return false;
        }
      }
      // for GMAT, the score should be in the range of 200-800
      if (academic === "GMAT") {
        if (Number(userMessage) < 200 || Number(userMessage) > 800) {
          setinputStatus("error");
          setinputHelp("Please enter a valid score, in the range of 200-800");
          return false;
        }
      }
      // for SAT, the score should be in the range of 400-1600
      if (academic === "SAT") {
        if (Number(userMessage) < 400 || Number(userMessage) > 1600) {
          setinputStatus("error");
          setinputHelp("Please enter a valid score, in the range of 400-1600");
          return false;
        }
      }
      // for ACT, the score should be in the range of 1-36
      if (academic === "ACT") {
        if (Number(userMessage) < 1 || Number(userMessage) > 36) {
          setinputStatus("error");
          setinputHelp("Please enter a valid score, in the range of 1-36");
          return false;
        }
      }
      // MAT, the score should be in the range of 200-800
      if (academic === "MAT") {
        if (Number(userMessage) < 200 || Number(userMessage) > 800) {
          setinputStatus("error");
          setinputHelp("Please enter a valid score, in the range of 200-800");
          return false;
        }
      }
    }
    // field-of-study validation
    if (currentStage === "field-of-study") {
      // if the message is not from the options, then return false
      // loop through the ServerOptions
      let _options: any = [];
      ServerOptions.forEach((option: any) => {
        _options.push(option.name);
      });

      // @ts-ignore
      if (!_options.includes(userMessage)) {
        setinputStatus("error");
        setinputHelp("Please select a valid or type your field of study");
        return false;
      }
    }

    if (currentStage === "highest-education-work-experience") {
      // if the message is not from the options, then return false
      // @ts-ignore
      if (degree === "MBA") {
        if (userMessage !== "Yes" && userMessage !== "No") {
          setinputStatus("error");
          setinputHelp("Please select a valid option");
          return false;
        }
      }
    }
    // work-experience-months, it should number or float
    if (currentStage === "work-experience-months") {
      // @ts-ignore
      if (isNaN(userMessage)) {
        setinputStatus("error");
        setinputHelp("Please enter a valid number");
        return false;
      }
    }
    // residential-status validation
    if (currentStage === "residentialstatus") {
      // if the message is not from the options, then return false
      // loop through the ServerOptions
      let hasResidentialStatus = false;
      ServerOptions.forEach((option: any) => {
        if (option === userMessage) {
          hasResidentialStatus = true;
        }
      });

      if (!hasResidentialStatus) {
        setinputStatus("error");
        setinputHelp("Please select a valid option");
        return false;
      }
    }
    // reset the input status
    setinputStatus("");
    setinputHelp("");
    return true;
  };

  const getInputType = (stage: string) => {
    if (stage === "email") {
      return "email";
    }

    return "text";
  };

  const validateEmail = (email: string) => {
    var filter = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
    return String(email).search(filter) != -1;
  };
  const processMessage = (userMessage: string, response: any) => {
    let old_messages = [...messages];
    if (userMessage) {
      old_messages = [...messages, { is_ai: false, message: userMessage, id: v4() }];
    }
    response.data.message = response.data.message.replaceAll("{name}", name);
    const is_markdown = response.data.is_message_md;
    old_messages = addAIMessage(response.data.message, old_messages, response.data.id, is_markdown);

    if (response.data.options) {
      setServerOptions(response.data.options);
    }

    if (response.data.type === "success") {
      if (response.data.next_message) {
        response.data.next_message = response.data.next_message.replaceAll("{name}", name);
        old_messages = addAIMessage(
          response.data.next_message,
          old_messages,
          response.data.id,
          response.data.is_next_message_md
        );
      }
      if (response.data.after_message) {
        setafterMessage(response.data.after_message);
      } else {
        setafterMessage("");
      }

      if (response.data.next_stage) {
        setnextStage(response.data.next_stage);
      }
      setMessages(old_messages);
      sethideButton(true);
      return true;
    } else {
      setMessages(old_messages);

      return false;
    }
  };

  const sendMessage = (userMessage: string) => {
    setshowLoader(true);
    setinputDisabled(true);
    // strip the white spaces before and after the message
    userMessage = userMessage.trim();
    if (!validateUserInput(userMessage)) {
      setshowLoader(false);
      setinputDisabled(false);
      setshowErrorShake(true);
      return;
    }
    setServerOptions([]);
    // if the message is "/resend", then resend the last message
    if (userMessage === "/resend") {
      // get the last user message
      let lastUserMessage = "";
      messages.forEach((message: any) => {
        if (!message.is_ai) {
          lastUserMessage = message.message;
        }
      });
    }
    switch (currentStage) {
      case "name":
        // Make captial on first letter
        userMessage = userMessage.charAt(0).toUpperCase() + userMessage.slice(1);
        axios
          .post("/api/isperson", {
            session_id: session_id,
            message: userMessage
          })
          .then((response) => {
            if (processMessage(userMessage, response)) {
              setname(userMessage);
            }
          })
          .then(() => {
            setshowLoader(false);
            play();
          });
        break;
      case "email":
        axios
          .post("/api/isemail", {
            session_id: session_id,
            message: userMessage
          })
          .then((response) => {
            if (processMessage(userMessage, response)) {
              setemail(userMessage);
            }
          })
          .then(() => {
            setshowLoader(false);
            play();
          });
        break;
      case "phonenumber":
        axios
          .post("/api/isphone", {
            session_id: session_id,
            message: userMessage
          })
          .then((response) => {
            if (processMessage(userMessage, response)) {
              setphonenumber(userMessage);
              if (response.data.options) {
                setServerOptions(response.data.options);
              }
            }
          })
          .then(() => {
            setshowLoader(false);
            play();
          });
        break;
      case "nationality":
        axios
          .post("/api/isnationality", {
            session_id: session_id,
            message: userMessage
          })
          .then((response) => {
            if (processMessage(userMessage, response)) {
              setnationality(userMessage);
              setcountryCodeUser(response.data.country_code_user);
              // country code
              setcountry(response.data.country);
              setcountry_name(response.data.country_name_user);
            }
            if (response.data.options) {
              setServerOptions(response.data.options);
              setresidential_popover(true);
            }
          })
          .finally(() => {
            setshowLoader(false);
            play();
          });
        break;
      case "country":
        axios
          .post("/api/iscountry", {
            session_id: session_id,
            message: userMessage
          })
          .then((response) => {
            if (processMessage(userMessage, response)) {
              setcountry(response.data.country);
              setcountryCode(response.data.country_code);
            }
          })
          .then(() => {
            setshowLoader(false);
            play();
          });
        break;
      case "degree":
        axios
          .post("/api/isdegree", {
            session_id: session_id,
            message: userMessage
          })
          .then((response) => {
            if (processMessage(userMessage, response)) {
              setdegree(response.data.degree);
              setis_eu_or_uk(response.data.is_eu_or_uk);
            }
          })
          .then(() => {
            setshowLoader(false);
            play();
          });
        break;
      case "highest-education-work-experience":
        if (degree !== "MBA") {
          axios
            .post("/api/iseducation", {
              session_id: session_id,
              message: userMessage
            })
            .then((response) => {
              if (processMessage(userMessage, response)) {
                sethighesteducation(response.data.highesteducation);
              }
            })
            .then(() => {
              setshowLoader(false);
              play();
            });
        } else {
          axios
            .post("/api/isworkexperience", {
              session_id: session_id,
              message: userMessage
            })
            .then((response) => {
              if (processMessage(userMessage, response)) {
                sethighesteducation(response.data.work_experience);
              }
            })
            .then(() => {
              setshowLoader(false);
              play();
            });
        }
        break;
      case "highest-education":
        axios
          .post("/api/iseducation", {
            session_id: session_id,
            message: userMessage
          })
          .then((response) => {
            if (processMessage(userMessage, response)) {
              sethighesteducation(response.data.highesteducation);
            }
          })
          .then(() => {
            setshowLoader(false);
            play();
          });
        break;
      case "gained-percentage":
        axios
          .post("/api/isgainedpercentage", {
            session_id: session_id,
            message: userMessage
          })
          .then((response) => {
            if (processMessage(userMessage, response)) {
              setgainedpercentage(response.data.gained_percentage);
              if (response.data.options) {
                setServerOptions(response.data.options);
              }
            }
          })
          .then(() => {
            setshowLoader(false);
            play();
          });
        break;
      case "education-test":
        axios
          .post("/api/iseducationtest", {
            session_id: session_id,
            message: userMessage
          })
          .then((response) => {
            if (processMessage(userMessage, response)) {
              seteducationtest(response.data.education_test);
            }
          })
          .then(() => {
            setshowLoader(false);
            play();
          });
        break;
      case "education-score":
        axios
          .post("/api/iseducationtestscore", {
            session_id: session_id,
            message: userMessage
          })
          .then((response) => {
            if (processMessage(userMessage, response)) {
              seteducationtestscore(response.data.education_score);
            }
          })
          .then(() => {
            setshowLoader(false);
            play();
          });
        break;
      case "academic-test":
        axios
          .post("/api/isacademictest", {
            session_id: session_id,
            message: userMessage
          })
          .then((response) => {
            if (processMessage(userMessage, response)) {
              setacademictest(response.data.academic_test);
            }
            setServerOptions(response.data.options);
          })
          .then(() => {
            setshowLoader(false);
            play();
          });
        break;
      case "academic-score":
        axios
          .post("/api/isacademicscore", {
            session_id: session_id,
            message: userMessage
          })
          .then((response) => {
            if (processMessage(userMessage, response)) {
              setacademicscore(response.data.academic_score);
              setServerOptions(response.data.options);
              setisPopoverOver(true);
            }
          })
          .then(() => {
            setshowLoader(false);
            play();
          });
        break;
      case "residentialstatus":
        axios
          .post("/api/isresidentialstatus", {
            session_id: session_id,
            message: userMessage
          })
          .then((response) => {
            if (processMessage(userMessage, response)) {
              setresidential_status(response.data.residentialstatus);
              setServerOptions(response.data.options);
              setisPopoverOver(true);
            }
          })
          .finally(() => {
            setshowLoader(false);
            play();
          })
          .catch((error) => {
            console.log(error);
            customMessage("Some error occured, please try again");
          });
        break;
      case "field-of-study":
        setisPopoverOver(false);
        axios
          .post("/api/isfieldofstudy", {
            session_id: session_id,
            message: userMessage
          })
          .then((response) => {
            if (processMessage(userMessage, response)) {
              setfieldOfStudy(response.data.field_of_study);
              setinputDisabled(true);
            }
          })
          .then(() => {
            setshowLoader(false);
            play();
          });
        break;
      case "work-experience-months":
        axios
          .post("/api/isworkexperiencemonths", {
            session_id: session_id,
            message: userMessage
          })
          .then((response) => {
            if (processMessage(userMessage, response)) {
              setinputDisabled(true);
              setworkexprience(response.data.work_experience);
            }
          })
          .then(() => {
            setshowLoader(false);
            play();
          });
        break;
      case "wait-for-result":
        axios
          .post("/api/waitforresult", {
            session_id: session_id
          })
          .then((response) => {
            if (processMessage(userMessage, response)) {
              setinputDisabled(true);
            }
            play();
          });
        break;
      default:
        break;
    }

    setUserMessage("");
    setinputDisabled(false);

    setTimeout(() => {
      ScrollToBottom();
    }, 100);
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      sendMessage(userMessage);
    }
  };

  const fetchResults = () => {
    setshowLoader(true);
    axios
      .post("/api/getresult", {
        session_id: session_id,
        // session_id: "15f6830a-0287-4511-a93c-3acfb713e873"
      })
      .then((response) => {
        if (processMessage("", response)) {
          setinputDisabled(true);
          setresults(response.data.options.results);
        }
      })
      .finally(() => {
        setshowLoader(false);
        // hide the button
        sethideButton(true);
      });
  };

  useEffect(() => {
    if (!userCountry) {
      setshowLoader(true);
      antdMessage.destroy();
      antdMessage.loading("Loading...");
      axios
        .post("/api/getcountry")
        .then((response) => {
          processMessage("", response);
          setuserCountry(response.data.country);
          setuserCountryFlag(response.data.flag);
          setshowLoader(false);
          if (!session_id) {
            setsession_id(response.data.session_id);
          }
        })
        .finally(() => {
          setshowLoader(false);
          play();
          antdMessage.destroy();
        });
    }
  }, []);

  useEffect(() => {
    if (showErrorShake) {
      setTimeout(() => {
        setshowErrorShake(false);
      }, 1000 * 0.5);
    }
  }, [showErrorShake]);

  useEffect(() => {
    if (currentStage === "field-of-study" || currentStage === "system-hault") {
      // disable input
      setinputDisabled(true);
    } else if (currentStage === "wait-for-result") {
      // call the database
      fetchResults();
    }
    if (currentStage === "field-of-study") {
      setisPopoverOver(true);
    }
  }, [currentStage]);

  useEffect(() => {
    if (session_id) {
      // create a new session
      axios
        .post("/api/newsession", {
          session_id: session_id
        })
        .then((response) => {});
    }
  }, [session_id]);
  /**
   * `name`, `email`, `mobile_number`, `session_id`, `interesed_country`, `interesed_degree`, `highest_education`, `lang_test`, `lang_score`, `academic_test`, `academic_score`,
   */
  useEffect(() => {
    if (session_id) {
      axios
        .post("/api/update_user", {
          name: name || "",
          email: email || "",
          mobile_number: phonenumber || "",
          session_id: session_id || "",
          interesed_country: country || "",
          interesed_degree: degree || "",
          highest_education: highesteducation || "",
          highest_education_percentage: gainedpercentage || "",
          lang_test: educationtest || "",
          lang_score: educationtestscore || "",
          academic_test: academic || "",
          academic_score: academicscore || "",
          field_of_study: fieldOfStudy || "",
          work_experience: workexprience || "",
          category_id: categoryId || "",
          country_id: countryId || "",
          nationality: nationality ?? "",
          country_code: countryCodeUser ?? "",
          residential_status: residential_status ?? "",
          country_name: country_name || ""
        })
        .then((response) => {});
    }
  }, [
    name,
    email,
    phonenumber,
    session_id,
    country,
    degree,
    highesteducation,
    gainedpercentage,
    educationtest,
    educationtestscore,
    academic,
    academicscore,
    fieldOfStudy,
    workexprience,
    categoryId,
    countryId,
    nationality,
    countryCodeUser,
    residential_status,
    country_name
  ]);

  // listen for the array messages changes
  useEffect(() => {
    if (session_id) {
      axios.post("/api/chat-history", {
        session_id: session_id,
        messages: messages
      });
    }
  }, [messages]);

  useEffect(() => {
    // @ts-ignore
    wrapperRef.current.style.zoom = "90%";
  }, []);

  return (
    <div style={{ height: "100vh", display: "flex", flexDirection: "column" }}>
      <p>&nbsp;</p>
      <Drawer
        open={courseDrawer}
        onClose={() => setcourseDrawer(false)}
        title={
          <>
            <img
              src={process.env.REACT_APP_BACKEND + "/" + courseData["institute_logo"]}
              width={30}
              style={{
                borderRadius: "6px"
              }}
            />
            {courseData["institute_name"] + " - " + courseData["campus_name"]}
          </>
        }
        width={isMobile ? "98vw" : "50vw"}
      >
        {courseData["courses"] &&
          courseData["courses"].map((course: any) => (
            <div
              style={{
                position: "relative",
                marginBottom: "12px"
              }}
            >
              <img
                //     position: absolute;
                // left: 0px;
                // top: 0px;
                // z-index: 100;
                // width: 50px;
                // top: -20px;
                // left: -20px;
                // border-radius: 8px;
                // opacity: 0.5;
                src={process.env.PUBLIC_URL + "/dot-dot-dot.png"}
                style={{
                  position: "absolute",
                  zIndex: 100,
                  width: "25px",
                  top: "-10px",
                  left: "-1<0px",
                  opacity: 0.5
                }}
              />
              <Card
                hoverable
                style={{
                  marginBottom: "12px",
                  overflow: "hidden"
                }}
              >
                <svg
                  width={600}
                  height={600}
                  viewBox="0 0 600 600"
                  style={{
                    opacity: 0.1,
                    position: "absolute",
                    left: "155px",
                    transform: "rotate(53deg)"
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  className="TwoColumnWithVideo__DecoratorBlob1-sc-apwiss-10 gamNjv"
                >
                  <path
                    d="M176.2-106.9c32.5 61.4 25.6 140.4-10.4 206.3C129.9 165.3 65 218.2-9.2 223.5c-74.2 5.3-157.7-36.8-192.4-102.1-34.8-65.2-21-153.6 20.7-220.3 41.8-66.8 111.3-111.9 182.1-112.6 70.7-.7 142.6 43.2 175 104.6z"
                    fill="#6415ff"
                    transform="translate(300 300)"
                  />
                </svg>
                <Descriptions title={course["course_name"]}>
                  <Descriptions.Item label="Intake">{course["course_intake"]}</Descriptions.Item>
                  <Descriptions.Item label="IELTS Score">{course["ielts_score"]}</Descriptions.Item>
                  <Descriptions.Item label="Fee" span={3}>
                    {course["course_fee"]}
                  </Descriptions.Item>
                  <Descriptions.Item label="Duration" span={2}>
                    {course["course_duration"]}
                  </Descriptions.Item>
                </Descriptions>
                <div
                  style={{
                    textAlign: "right"
                  }}
                >
                  <Button
                    type="primary"
                    onClick={() => {
                      // show a notification as modal with the message "One of our counsellor will contact you soon"
                      setshowModal(true);
                      // play the sound
                      play();
                      setcourseContentShow(false);
                      // after 3 seconds, close the modal
                      setTimeout(() => {
                        setcourseContentShow(true);
                      }, 1000 * 3);
                    }}
                  >
                    Apply Now
                  </Button>
                </div>
              </Card>
            </div>
          ))}
      </Drawer>
      <Drawer
        open={showDrawer}
        onClose={() => setshowDrawer(false)}
        title="View Results"
        width={isMobile ? "98vw" : "80vw"}
      >
        <Table
          columns={
            isMobile
              ? [
                  {
                    title: " ",
                    render: (text: any, record: any) => (
                      <Card
                        hoverable
                        style={{
                          width: "80vw"
                        }}
                      >
                        <Descriptions
                          title={
                            <>
                              {" "}
                              <img
                                style={{
                                  width: "25px",
                                  borderRadius: "6px",
                                  marginRight: "12px"
                                }}
                                src={process.env.REACT_APP_BACKEND + "/" + record.institute_logo}
                              />{" "}
                              {record.institute_name}
                            </>
                          }
                        >
                          <Descriptions.Item label="Campus">{record.campus_name}</Descriptions.Item>
                          <Descriptions.Item label="Country">
                            {record.country_name}
                          </Descriptions.Item>
                          <Descriptions.Item label="Course Count">
                            {record.course_count}
                          </Descriptions.Item>
                          <Descriptions.Item label="QS World Ranking">
                            {record.qs_world}
                          </Descriptions.Item>
                          <Descriptions.Item label="Web Ranking (World)">
                            {record.webometrics_world}
                          </Descriptions.Item>
                          <Descriptions.Item label="Web Ranking (Country)">
                            {record.webometrics_national}
                          </Descriptions.Item>

                          <p>
                            <Button
                              type="primary"
                              onClick={() => {
                                setcourseData(record);
                                setcourseDrawer(true);
                              }}
                            >
                              View Courses
                            </Button>
                          </p>
                        </Descriptions>
                      </Card>
                    )
                  }
                ]
              : ([
                  {
                    title: "University",
                    dataIndex: "institute_name",
                    key: "institute_name",
                    sorter: (a: any, b: any) => a.institute_name.length - b.institute_name.length,
                    render: (text: any, record: any) => (
                      <Button
                        type="link"
                        onClick={() => {
                          setcourseData(record);
                          setcourseDrawer(true);
                        }}
                      >
                        <img
                          src={process.env.REACT_APP_BACKEND + "/" + record.institute_logo}
                          width={25}
                        />{" "}
                        &nbsp; {text} <LinkOutlined />
                      </Button>
                    )
                  },
                  {
                    title: "Country",
                    dataIndex: "country_name",
                    key: "country_name",
                    sorter: (a: any, b: any) => a.country_name.length - b.country_name.length
                  },
                  {
                    title: "Campus",
                    dataIndex: "campus_name",
                    key: "campus_name",
                    sorter: (a: any, b: any) => a.campus_name.length - b.campus_name.length
                  },
                  ,
                  {
                    title: "Course Count",
                    dataIndex: "course_count",
                    key: "course_count",
                    sorter: (a: any, b: any) => a.course_count - b.course_count
                  },
                  {
                    title: "QS World Ranking",
                    dataIndex: "qs_world",
                    key: "qs_world",
                    sorter: (a: any, b: any) => {
                      if (!a.qs_world) return 1;
                      if (!b.qs_world) return -1;
                      return a.qs_world - b.qs_world;
                    }
                  },
                  {
                    title: "Web Ranking (World)",
                    dataIndex: "webometrics_world",
                    key: "webometrics_world",
                    sorter: (a: any, b: any) => a.webometrics_world - b.webometrics_world
                  },
                  {
                    title: "Web Ranking (Country)",
                    dataIndex: "webometrics_national",
                    key: "webometrics_national",
                    sorter: (a: any, b: any) => a.webometrics_national - b.webometrics_national
                  }
                ] as any)
          }
          dataSource={results}
        />
      </Drawer>
      <Modal title="Thank you for your interest" open={showModal} footer={null} closable={false}>
        {courseContentShow && (
          <div>
            <p>
              One of our counsellor will contact you soon. If you have any questions, please feel
              free to contact us at <a href="mailto:info@bheuni.io">info@bheuni.io</a>
            </p>
            <p
              style={{
                textAlign: "right"
              }}
            >
              <Button
                type="primary"
                onClick={() => {
                  setshowModal(false);
                }}
              >
                Close
              </Button>
            </p>
          </div>
        )}
        {!courseContentShow && (
          <Lottie
            loop
            animationData={lottieJson}
            play
            style={{ width: 300, height: 300, margin: "0 auto" }}
          />
        )}
      </Modal>

      <div
        style={{ height: "80vh", overflowY: "scroll", paddingBottom: "12px" }}
        className="chat-container"
      >
        {messages.map((message: any, index: any) => (
          <div
            key={message.id}
            className={message.is_ai ? "chat-message-row ai" : "chat-message-row human"}
          >
            <div className="chat-message">
              {message.is_ai ? (
                <>
                  {message.animate ? (
                    <>
                      <Typewriter
                        text={message.message}
                        beforeAnimation={() => {
                          setinputDisabled(true);
                        }}
                        afterAnimation={() => {
                          setinputDisabled(false);
                          // focus on input
                          if (!isMobile || (isMobile && !props.isDrawerOpen)) {
                            //@ts-ignore
                            inputRef.current.focus();
                          }
                          // scroll to bottom
                          ScrollToBottom();

                          // add new message if next message is available
                          if (afterMessage) {
                            setTimeout(() => {
                              let old_messages = [...messages];
                              old_messages = addAIMessage(afterMessage, old_messages, v4(), true);
                              setMessages(old_messages);
                              ScrollToBottom();
                              setafterMessage("");

                              // if next stage is available
                              if (nextStage) {
                                setTimeout(() => {
                                  setcurrentStage(nextStage);
                                  setnextStage("");
                                  // play the sound
                                }, 100);
                              }
                            }, 100);
                          } else {
                            // if next stage is available
                            if (nextStage) {
                              setTimeout(() => {
                                setcurrentStage(nextStage);
                                setnextStage("");
                              }, 100);
                            }
                            sethideButton(false);
                          }
                        }}
                        onEveryType={() => {
                          ScrollToBottom();
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <ReactMarkdown remarkPlugins={[remarkGfm]}>{message.message}</ReactMarkdown>
                    </>
                  )}
                </>
              ) : (
                <>{message.message}</>
              )}
            </div>
          </div>
        ))}
        {showLoader && (
          <div className="chat-message-row ai">
            <div className="chat-message loader">
              <Spin />
            </div>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>
      {/* if stage is country */}
      {!hideButton && (
        <>
          {currentStage === "country" && (
            <div>
              {ServerOptions &&
                ServerOptions.map((option: any, index: any) => (
                  <span key={option.id}>
                    <Button size="small" onClick={() => customMessage(option.name, option.id)}>
                      <img
                        src={getFlag(option.flag)}
                        width={15}
                        style={{
                          marginTop: -2,
                          borderRadius: "50%"
                        }}
                      />{" "}
                      &nbsp; {option.name}
                    </Button>
                    &nbsp;
                  </span>
                ))}
            </div>
          )}
          {currentStage === "degree" && (
            <div className="degree">
              <Button size="small" onClick={() => customMessage("Undergraduate Degree")}>
                <img src={undergrad} alt="Undergraduate Degree" width="15px" /> Undergraduate Degree
              </Button>
              &nbsp;
              <Button size="small" onClick={() => customMessage("Postgraduate Degree")}>
                <img src={postgrad} alt="Postgraduate Degree" width="15px" /> Postgraduate Degree
              </Button>
              &nbsp;
              <Button size="small" onClick={() => customMessage("MBA")}>
                <img src={mba} alt="MBA" width="15px" /> MBA
              </Button>
            </div>
          )}
          {currentStage === "highest-education" && (
            <>
              <div className="highest-education">
                <Button size="small" onClick={() => customMessage("Undergraduate Degree")}>
                  <img
                    src={undergrad}
                    alt="Undergraduate Degree"
                    width="15px"
                    className="-mt-3 mr-3"
                  />{" "}
                  Undergraduate Degree or Equivalent
                </Button>
                &nbsp;
                {/* Post Grad Degree */}
                <Button size="small" onClick={() => customMessage("Postgraduate Degree")}>
                  <img
                    src={postgrad}
                    alt="Postgraduate Degree"
                    width="15px"
                    className="-mt-3 mr-3"
                  />
                  Postgraduate Degree or Equivalent
                </Button>
                &nbsp;
                {/*  */}
              </div>
            </>
          )}
          {currentStage === "highest-education-work-experience" && (
            <div className="highest-education">
              {degree === "Postgraduate Degree" && (
                <>
                  {/* UG Degree */}
                  <Button size="small" onClick={() => customMessage("Undergraduate Degree")}>
                    <img
                      src={undergrad}
                      alt="Undergraduate Degree"
                      width="15px"
                      className="-mt-3 mr-3"
                    />{" "}
                    Undergraduate Degree or Equivalent
                  </Button>
                  &nbsp;
                  {/* Post Grad Degree */}
                  <Button size="small" onClick={() => customMessage("Postgraduate Degree")}>
                    <img
                      src={postgrad}
                      alt="Postgraduate Degree"
                      width="15px"
                      className="-mt-3 mr-3"
                    />
                    Postgraduate Degree or Equivalent
                  </Button>
                  &nbsp;
                  {is_eu_or_uk && (
                    <Button size="small" onClick={() => customMessage("No Formal Education")}>
                      No Formal Education
                    </Button>
                  )}
                  {is_eu_or_uk && (
                    // Work Experience
                    <Button size="small" onClick={() => customMessage("Work Experience")}>
                      Work Experience in Related Field
                    </Button>
                  )}
                </>
              )}
              {degree === "Undergraduate Degree" && (
                <>
                  <Button
                    size="small"
                    onClick={() => customMessage("High School Sceondary School")}
                  >
                    High School Education or Equivalent
                  </Button>
                  &nbsp;
                  <Button size="small" onClick={() => customMessage("Diploma")}>
                    Diploma of Higher Education or Equivalent
                  </Button>
                  &nbsp;
                  <Button size="small" onClick={() => customMessage("Undergraduate Degree")}>
                    Undergraduate Degree
                  </Button>
                  &nbsp;
                  {is_eu_or_uk && (
                    <Button size="small" onClick={() => customMessage("No Formal Education")}>
                      No Formal Education
                    </Button>
                  )}
                  {is_eu_or_uk && (
                    // Work Experience
                    <Button size="small" onClick={() => customMessage("Work Experience")}>
                      Work Experience in Related Field
                    </Button>
                  )}
                </>
              )}
              {degree === "MBA" && (
                <>
                  {/* Yes  */}
                  <Button size="small" onClick={() => customMessage("Yes")}>
                    Yes
                  </Button>
                  &nbsp;
                  {/* No */}
                  <Button size="small" onClick={() => customMessage("No")}>
                    No
                  </Button>
                </>
              )}
            </div>
          )}
          {currentStage === "education-test" && (
            <div className="education-test">
              <Button size="small" onClick={() => customMessage("IELTS")}>
                <img src={ielts} alt="IELTS" width="15px" /> IELTS
              </Button>
              &nbsp;
              <Button size="small" onClick={() => customMessage("TOEFL")}>
                <img src={toefl} alt="TOEFL" width="15px" /> TOEFL
              </Button>
              &nbsp;
              <Button size="small" onClick={() => customMessage("Duolingo")}>
                <img src={duolingo} alt="Duolingo" width="15px" /> Duolingo
              </Button>
              &nbsp;
              <Button size="small" onClick={() => customMessage("PTE")}>
                <img src={pte} alt="PTE" width="15px" /> PTE
              </Button>
              &nbsp;
              <Button size="small" onClick={() => customMessage("Not Planing to take Any")}>
                <img src={notplainning} alt="Not Planing to take Any" width="15px" /> Not Planing to
                take Any
              </Button>
            </div>
          )}
          {currentStage === "academic-test" && (
            <div className="degree">
              <Button size="small" onClick={() => customMessage("GRE")}>
                <img src={gre} alt="GRE" width="15px" /> GRE
              </Button>
              &nbsp;
              <Button size="small" onClick={() => customMessage("GMAT")}>
                <img src={gmat} alt="GMAT" width="15px" /> GMAT
              </Button>
              &nbsp;
              <Button size="small" onClick={() => customMessage("MAT")}>
                <img src={mat} alt="MAT" width="15px" /> MAT
              </Button>
              &nbsp;
              <Button size="small" onClick={() => customMessage("SAT")}>
                <img src={sat} alt="SAT" width="15px" /> SAT
              </Button>
              &nbsp;
              <Button size="small" onClick={() => customMessage("ACT")}>
                <img src={act} alt="ACT" width="15px" /> ACT
              </Button>
              &nbsp;
              <Button size="small" onClick={() => customMessage("Not Planing to take Any")}>
                <img src={notplainning} alt="Not Planing to take Any" width="15px" />
                Not Planing to take Any
              </Button>
            </div>
          )}
          {currentStage === "field-of-study" && (
            <>
              <div className="degree">
                <Popover
                  title="Please select your field of study"
                  placement="topLeft"
                  open={isPopoverOver}
                  content={
                    <div
                      className="field-of-study"
                      style={{
                        width: isMobile ? "78vw" : "400px",
                        height: 400,
                        overflowY: "scroll",
                        overflowX: "hidden",
                        textAlign: "left",
                        position: "relative"
                      }}
                    >
                      {ServerOptions &&
                        ServerOptions.map((field: any, index) => (
                          <Button block onClick={() => customMessage(field.name, field.id)}>
                            {field.name}
                          </Button>
                        ))}
                    </div>
                  }
                ></Popover>
              </div>
            </>
          )}
        </>
      )}
      {currentStage === "system-hault" && (
        <>
          <Alert
            message="Change Track"
            description={
              <>
                <ReactMarkdown remarkPlugins={[remarkGfm]}>
                  {messages[messages.length - 1].message}
                </ReactMarkdown>
                <br />
                <Divider />
                Please explore our master's programs <br />
                <br />
                <Button
                  type="primary"
                  onClick={() => {
                    // reload page
                    window.location.reload();
                  }}
                >
                  Restart
                </Button>
              </>
            }
            type="error"
            showIcon
          />
        </>
      )}
      {!showLoader && currentStage === "wait-for-result" && (
        <>
          <Alert
            message="Result Generated"
            description={
              <>
                <ReactMarkdown remarkPlugins={[remarkGfm]}>
                  {messages[messages.length - 1].message}
                </ReactMarkdown>
                <br />
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  onClick={() => {
                    setshowDrawer(true);
                  }}
                >
                  View Result <EyeOutlined />
                </Button>
              </>
            }
            type="success"
            showIcon
          />
        </>
      )}
      {currentStage === "residentialstatus" && (
        <div className="residentialstatus">
          {ServerOptions &&
            ServerOptions.map((field: any, index) => (
              <span key={field}>
                <Button type="dashed" onClick={() => customMessage(field)}>
                  {field.name ? field.name : field}
                </Button>
                &nbsp;&nbsp;
              </span>
            ))}
        </div>
      )}
      <div className={currentStage}>
        <div className={showErrorShake ? "shake-right-to-left" : ""}>
          {/* If state is course */}
          {inputHelp && <Typography.Text type="danger">{inputHelp}</Typography.Text>}
          <div ref={wrapperRef} style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                paddingTop: "10px"
                //  place at bottom of page
              }}
            >
              <Input.Search
                value={userMessage}
                onChange={(event) => setUserMessage(event.target.value)}
                onSearch={() => sendMessage(userMessage)}
                type={getInputType(currentStage)}
                pattern={
                  // add plus sign if the input is phone number     var regex = /^\+(?:[0-9] ?){6,14}[0-9]$/;

                  currentStage === "phonenumber" ? "+d{1,4}sd{3}-d{3}-d{4}" : undefined
                }
                onFocus={() => {
                  // on mobile reset the zoom to 100% for body
                  //@ts-ignore
                  document.body.style.zoom = "100%";
                  // scroll to the input just above the keyboard
                  setTimeout(() => {
                    ScrollToBottom();
                  }, 100);
                }}
                enterButton={
                  <Button
                    type="primary"
                    onClick={() => sendMessage(userMessage)}
                    icon={<SendOutlined />}
                    danger={inputStatus === "error"}
                  />
                }
                placeholder={"Type your message here..."}
                autoFocus={true}
                maxLength={100}
                disabled={inputDisabled}
                ref={inputRef}
                status={inputStatus}
              />
            </div>
          </div>
        </div>
      </div>
      {process.env.NODE_ENV === "development" && isDebug && (
        <div
          style={{
            position: "fixed",
            top: 0,
            right: 0,
            zIndex: 1000
          }}
        >
          <Button
            onClick={() => {
              setcurrentStage("wait-for-result");
              fetchResults();
            }}
          >
            Debug
          </Button>
        </div>
      )}
      {process.env.NODE_ENV === "development" && isDebug && (
        <pre>
          <code>{JSON.stringify({ currentStage }, null, 2)}</code>
        </pre>
      )}
      <Build />
    </div>
  );
}

export default Chat;
 